<template>
  <div>
    <PhysicalActivitiesComp v-bind:mobile="mobile" />
    <FollowUs v-bind:mobile="mobile" />
  </div>
</template>

<script>
  import PhysicalActivitiesComp from '@/components/desktop/WantPractice/PhysicalActivitiesComp.vue'
  import FollowUs from '@/components/desktop/FollowUs.vue'

  export default {
    name: 'PhysicalActivities',
    props: ['mobile'],
    components: {
      PhysicalActivitiesComp,
      FollowUs
    },
  }
</script>
