<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle twoLines'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      <v-col :cols="cols()" class="leftSection" v-if="!mobile">
        <h3 class="subtitle" v-html="$t('section1')"></h3>
        <div class="textSections" v-html="$t('section1Text')"></div>
        <v-btn
            rounded
            class="btnWantPractice"
            height="65px"
            width="500px"
            dark
            v-html="$t('wantPractice')"
            @click="routing('BalanceGym')"
            color="#57939E">
        </v-btn>
        <div class="imgLeft">
          <v-img
              :src="require('@/assets/vitaeImg/section29.png')"
              contain
              height="220"
          />
        </div>
        <h3 class="subtitle" v-html="$t('section3')"></h3>
        <div class="textSections" v-html="$t('section3Text')"></div>
        <v-btn
            rounded
            @click="routing('NordicWalking')"
            class="btnWantPractice"
            height="65px"
            width="500px"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div class="imgLeft">
          <v-img
              :src="require('@/assets/vitaeImg/section31.png')"
              contain
              height="220"
          />
        </div>
        <h3 style="margin-top: 70px; font-size: 19px;" class="subtitle" v-html="$t('section5')"></h3>
        <div class="textSections" v-html="$t('section5Text')"></div>
        <v-btn
            rounded
            @click="routing('IndividualSupport')"
            class="btnWantPractice"
            height="65px"
            width="500px"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div class="imgLeft">
          <v-img
              :src="require('@/assets/vitaeImg/section16.png')"
              contain
              height="220"
          />
        </div>
        <h3 style="font-size: 19px; margin-top: 20px"  class="subtitle" v-html="$t('section7')"></h3>
        <div class="textSections" v-html="$t('section7Text')"></div>
        <v-btn
            rounded
            class="btnWantPractice"
            height="65px"
            width="500px"
            dark
            @click="routing('Activities2')"
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
      </v-col>
      <v-col v-bind:cols="mobile ? 1 : 2">
        <v-img
            :src="require('@/assets/picto/separator8.svg')"
            contain
            v-bind:height="mobile ? 3400 : 2800"
        />
      </v-col>
      <v-col :cols="cols()" v-bind:class="mobile ? 'rightSectionMobile' : 'rightSection'">
        <h3 v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'" v-html="$t('section1')" v-if="mobile"></h3>
        <div v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section1Text')" v-if="mobile"></div>
        <v-btn
            v-if="mobile"
            rounded
            @click="routing('BalanceGym')"
            class="btnWantPracticeMobile"
            height="50px"
            width="300px"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              :src="require('@/assets/vitaeImg/section28.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
        <br v-if="mobile">
        <h3 v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'" v-html="$t('section2')"></h3>
        <div v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section2Text')"></div>
        <v-btn
            rounded
            @click="routing('ActiveGym')"
            v-bind:class="mobile ? 'btnWantPracticeMobile' : 'btnWantPractice'"
            v-bind:height="mobile ? '50px' : '65px'"
            v-bind:width="mobile ? '300px' : '500px'"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              :src="require('@/assets/vitaeImg/section30.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
        <h3 v-if="mobile" v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'" v-html="$t('section3')"></h3>
        <div v-if="mobile" v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section3Text')"></div>
        <v-btn
            rounded
            v-if="mobile"
            @click="routing('NordicWalking')"
            v-bind:class="mobile ? 'btnWantPracticeMobile' : 'btnWantPractice'"
            v-bind:height="mobile ? '50px' : '65px'"
            v-bind:width="mobile ? '300px' : '500px'"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-if="mobile" v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              v-if="mobile"
              :src="require('@/assets/vitaeImg/section31.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
        <h3 v-bind:style="mobile ? '' : 'margin-top: 90px'" v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'"  v-html="$t('section4')"></h3>
        <div v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section4Text')"></div>
        <v-btn
            rounded
            @click="routing('SportsTherapy')"
            v-bind:class="mobile ? 'btnWantPracticeMobile' : 'btnWantPractice'"
            v-bind:height="mobile ? '50px' : '65px'"
            v-bind:width="mobile ? '300px' : '500px'"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              :src="require('@/assets/vitaeImg/section32.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
        <br v-if="mobile">
        <h3
            v-bind:style="mobile ? 'margin-top: -40px' : ''"
            v-if="mobile" v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'"
            v-html="$t('section5')"></h3>
        <div v-if="mobile" v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section5Text')"></div>
        <v-btn
            rounded
            v-if="mobile"
            v-bind:class="mobile ? 'btnWantPracticeMobile' : 'btnWantPractice'"
            v-bind:height="mobile ? '50px' : '65px'"
            v-bind:width="mobile ? '300px' : '500px'"
            @click="routing('IndividualSupport')"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-if="mobile" v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              v-if="mobile"
              :src="require('@/assets/vitaeImg/section18.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
        <br v-if="mobile">
        <h3
            v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'"
            v-html="$t('section6')"></h3>
        <div v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section6Text')"></div>
        <v-btn
            rounded
            v-bind:class="mobile ? 'btnWantPracticeMobile' : 'btnWantPractice'"
            v-bind:height="mobile ? '50px' : '65px'"
            v-bind:width="mobile ? '300px' : '500px'"
            dark
            @click="routing('PhysicalActivitiesAway')"
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              :src="require('@/assets/vitaeImg/section28.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
        <h3
            v-if="mobile"
            v-bind:class="mobile ? 'subtitleMobile' : 'subtitle'"
            v-html="$t('section7')"
        ></h3>
        <div v-if="mobile" v-bind:class="mobile ? 'textSectionsMobile' : 'textSections'" v-html="$t('section7Text')"></div>
        <v-btn
            rounded
            v-if="mobile"
            @click="routing('Activities2')"
            v-bind:class="mobile ? 'btnWantPracticeMobile' : 'btnWantPractice'"
            v-bind:height="mobile ? '50px' : '65px'"
            v-bind:width="mobile ? '300px' : '500px'"
            dark
            v-html="$t('wantPractice')"
            color="#57939E">
        </v-btn>
        <div v-if="mobile" v-bind:class="mobile ? 'imgRightMobile' : 'imgRight'">
          <v-img
              v-if="mobile"
              :src="require('@/assets/vitaeImg/section14.png')"
              contain
              v-bind:height="mobile ? 140 : 220"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PhysicalActivitiesComp',
  props: ['mobile'],
  data: () => ({
    width: screen.width
  }),
  methods: {
    routing(nameRoute) {
      this.$vuetify.goTo(0)
      if (this.$route.name !== nameRoute) {
        this.$router.push({name: nameRoute}).then(() => {
          this.$vuetify.goTo(0)
        })
      }
    },
    cols () {
      if (this.mobile) {
        return 10
      } else if (this.width > 1500) {
        return 3
      } else {
        return 5
      }
    }
  },
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Adapted physical activities offered by the association",
    "section1": "The balance gym",
    "section1Text": "Soft adapted physical activity, geared towards balance and muscle building. <br> This activity adapts to all states of shape and all motivation.",
    "section2": "The active gym",
    "section2Text": "Adapted invigorating physical activity focused on muscle building and cardiovascular work.<br> This more intense activity is intended for people looking for an activity with more intensity.",
    "section3": "Nordic walking",
    "section3Text": "Our adapted Nordic walking is perfect for keeping in shape because it combines endurance work accessible to all and muscle strengthening of the whole body. <br><br> This activity is intended for people wishing to maintain good health through a fun and motivating outdoor activity.",
    "section4": "Sport therapy",
    "section4Text": "We are developing adapted physical activities to support people who are strongly deconditioned because of a pathology, an accident or COVID-19 towards better autonomy and a better quality of life. <br><br> These activities are intended for people who want to use physical activity as a non-drug therapy.",
    "section5": "Tailor-made individual support",
    "section5Text": "For different reasons, individual support is sometimes essential. <br>  The association offers you a tailor-made program that will best meet your desires, your state of fitness and your motivation.",
    "section6": "Video sport",
    "section6Text": "Practicing an adapted physical activity on video has many advantages. <br> We offer a maintenance gym activity focused on muscle building and flexibility work.",
    "section7": "My sport and health program in autonomy",
    "section7Text": "We offer remote programs for people wishing to practice independently. After a first inventory with an APA teacher, a remote program accessible via a personal online space is offered to you.",
    "wantPractice": "I want to practice !"
  },
  "fr": {
    "mainTitle": "Les activités physiques adaptées proposées par l'association",
    "section1": "La gym équilibre",
    "section1Text": "Activité physique adaptée douce, orientée sur l’équilibre et le renforcement musculaire. <br> Cette activité s’adapte à tous les états de formes et toutes les motivation.",
    "section2": "La gym active",
    "section2Text": "Activité physique adaptée tonifiante axée sur le renforcement musculaire et le travail cardiovasculaire. <br> Cette activité plus intense est destinée à des personnes recherchant une activité avec plus d’intensité.",
    "section3": "La marche nordique",
    "section3Text": "Notre marche nordique adaptée est parfaite pour se maintenir en forme car elle allie un travail d’endurance accessible à tous et un renforcement musculaire de l’ensemble du corps. <br><br> Cette activité est destinée à des personnes désireuses de se maintenir en bonne santé au travers d’une activité de pleine nature, ludique et motivante.",
    "section4": "Le sport thérapie",
    "section4Text": "Nous développons des activités physiques adaptées afin d’accompagner les personnes fortement déconditionnées à cause d’une pathologie, d’un accident ou de la COVID-19 vers une meilleure autonomie et une meilleure qualité de vie. <br><br> Ces activités sont destinées à des personnes désireuses d’utiliser l’activité physique comme thérapie non médicamenteuse.",
    "section5": "Les prises en charge individuelles sur mesure",
    "section5Text": "Pour différentes raisons, la prise en charge individuelle est parfois essentielle. <br>  L’association vous propose un programme sur mesure qui répondra au mieux à vos envies, votre état de forme et votre motivation.",
    "section6": "Le sport en visio",
    "section6Text": "Pratiquer une activité physique adaptée en visio comporte beaucoup d’avantages. <br> Nous proposons une activité de gym d’entretien axée autour du renforcement musculaire et d’un travail d’assouplissement.",
    "section7": "Mon programme sport santé en autonomie",
    "section7Text": "Nous proposons des programmes à distance pour les personnes souhaitant pratiquer en toute autonomie. Après un premier état des lieux avec un enseignant APA, un programme à distance accessible via un espace en ligne personnel vous est proposé.",
    "wantPractice": "Je souhaite pratiquer !"
  }
}
</i18n>

<style scoped>

@media screen and (max-width: 1500px) {
  .twoLines {
    text-align: center;
    padding-left: 300px;
    padding-right: 300px;
  }
  .firstSection {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 40px;
  }
}
@media screen and (min-width: 1500px) {
  .twoLines {
    text-align: center;
    padding-left: 550px;
    padding-right: 550px;
  }
  .firstSection {
    padding-top: 40px;
  }
}

.firstSectionMobile {
  padding-right: 15px;
}

.subtitle {
  padding-bottom: 40px;
  font-size: 22px;
  font-weight: normal !important;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.subtitleMobile {
  padding-bottom: 30px;
  padding-top: 15px;
  font-size: 20px;
  font-weight: normal !important;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.leftSection {
  text-align: right;
}

.imgLeft {
  margin-top: 65px;
  margin-left: 200px;
  padding-bottom: 100px;
}

.rightSection {
  text-align: left;
}

.rightSectionMobile {
  text-align: left;
  margin-left: 10px;
}

.imgRight {
  margin-top: 65px;
  margin-right: 200px;
  padding-bottom: 100px;
}

.imgRightMobile {
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 20px;
}

.textSections {
  color: #808080;
}

.textSectionsMobile {
  color: #808080;
  font-size: 14px;
}

.btnWantPractice {
  font-size: 18px;
  text-transform: none;
  margin-top: 40px;
  margin-bottom: 100px;
}

.btnWantPracticeMobile {
  font-size: 14px;
  text-transform: none;
  margin-top: 40px;
}

</style>
